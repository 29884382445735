var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        {
          attrs: { "content-class": "pt-1", lazy: "" },
          model: {
            value: _vm.tabIndex,
            callback: function ($$v) {
              _vm.tabIndex = $$v
            },
            expression: "tabIndex",
          },
        },
        _vm._l(_vm.tabs, function (tab, index) {
          return _c(
            "b-tab",
            {
              key: index,
              attrs: {
                title: tab.text,
                active: _vm.active(index),
                "title-item-class": "lisaweb",
              },
              on: {
                click: function ($event) {
                  return _vm.onTabClick(tab, index)
                },
              },
            },
            [
              _c(
                "keep-alive",
                [
                  _vm.selected === tab.name
                    ? _c(_vm.selected, { tag: "component" })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }